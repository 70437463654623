@import "src/colors.scss";

.ServiceCard {
  width: 363px;
  height: 629px;
  background: #ffffff;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  overflow: hidden;
  margin: 16px;
  align-items: center;
  display: flex;
  flex-direction: column;
  &__textContainer {
    color: $black;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    font-weight: 400;
    padding: 30px;
    max-width: 278px;
    text-align: center;
    h3 {
      font-size: 22px;
      margin-top: 12px;
      margin-bottom: 18px;
      font-weight: 600;
    }
  }
}
