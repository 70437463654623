.modal {
  position: fixed;
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  .content {
    position: relative;
    overflow: hidden;
    background: white;
    min-width: 300px;
    min-height: 100px;
    border-radius: 7px;
    button {
      position: absolute;
      top: 4px;
      right: 0px;
      border: none;
      background-color: transparent;
      svg {
        position: relative;
      }
      cursor: pointer;
    }
  }
}
