@import "src/colors.scss";

.EventCard {
  position: relative;
  width: 363px;
  height: 336px;
  cursor: pointer;
  &--small {
    width: 274px;
    height: 190px;
    .EventCard__imageContainer {
      height: 126px;
      min-height: auto;
      overflow: hidden;
    }
    .EventCard__descriptionContainer {
      padding: 0;
      padding-top: 12px;
      font-size: 12px;
    }
    .EventCard__month {
      margin-top: 0;
      font-size: 8px;
    }
    .EventCard__label {
      font-size: 8px;
      width: 75px;
      height: 21px;
    }
  }
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  margin: 16px;
  align-items: center;
  display: flex;
  flex-direction: column;
  &__label {
    position: absolute;
    right: 0;
    top: 0;
    background-color: $primary;
    font-size: 10px;
    width: 99px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
  &__imageContainer {
    min-height: 252px;
    overflow: hidden;
  }
  &__descriptionContainer {
    color: $black;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 400;
    padding: 30px;
    width: 80%;
  }
  &__title {
    font-size: 16px;
    text-transform: capitalize;
  }
  &__month {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
    margin-top: 8px;
  }
}
